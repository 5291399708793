export enum ArticlesProperty {
  id = '_id',
  title = 'title',
  subtitle = 'subtitle',
  text = 'text',
  plainText = 'plainText',
  category = 'category',
  videoPost = 'video_post',
  textPost = 'text_post',
  photoPost = 'photo_post',
  surveyPost = 'survey_post',
  time = 'time',
  date = 'date',
  create_date = 'create_date',
  editor = 'editor',
  display_name = 'display_name',
  url_title = 'url_title',
  priority = 'priority',
  published = 'published',
  draft = 'draft',
  image = 'image',
  image_list = 'image_list',
  tags = 'tags',
  category_articles = 'category_articles',
  notification = 'notification'
}
