  <div class="article">
    <div class="left">
      @if (article !== undefined) {
        <div class="tags">
          <div class="tag" *ngFor="let tag of article.tags" [routerLink]="'/search/' + tag.tag">
            <p>#{{ tag.tag }}</p>
          </div>
        </div>
        <div class="carousel">
          <button class="prev-button" (click)="prev()"><i class="fa fa-angle-left"></i></button>
          <div class="carousel-item" [ngClass]="carouselClass" *ngFor="let item of article.image_list; let i = index"
               [hidden]="i !== currentIndex" (click)="showGallery()">
            @if (!isVideo(item.url)) {
              <img ngSrc="{{item.url}}" alt="" fill="" style="position: relative" priority="">
            } @else {
              <video
                [src]="item.url"
                controls
                preload="auto"
                loop
                autoplay
                (click)="handleVideoClick($event)"
              >
              </video>
            }
          </div>
          <button class="next-button" (click)="next()"><i class="fa fa-angle-right"></i></button>
        </div>

        <!-- *ngIf="showFullScreen"-->
        <div class="full-size" style="{{styleGallery}}">
          <button (click)="showGallery()">
            X
          </button>
          <div class="carousel">
            <button class="prev-button" (click)="prev()"><i class="fa fa-angle-left"></i></button>
            <div class="carousel-item" *ngFor="let item of article.image_list; let i = index"
                 [hidden]="i !== currentIndex">
              @if (!isVideo(item.url)) {
                <img ngSrc="{{item.url}}" alt="" fill="" style="position: relative" priority="">
              } @else {
                <video
                  style="margin: 0 10%; pointer-events: none;"
                  width="80%"
                  height="400px"
                  controls
                >
                  <source [src]="item.url" type="video/mp4"/>
                </video>
              }
            </div>
            <button class="next-button" (click)="next()"><i class="fa fa-angle-right"></i></button>
          </div>
        </div>

        <div class="article-information-small-device">
          <div class="left-info" itemprop="datePublished">
            <p style="width: max-content;">{{ article[articlesProperty.display_name] }}</p>
            <div class="date">
              <p>{{ date }}</p>
              <p>{{ time }}</p>
            </div>
          </div>
          <div class="right-info">
            <div class="share-buttons">
              <a href="https://www.facebook.com/sharer.php?u={{CURRENT_URL}}" target="_blank">
                <i class="fab fa-facebook-f social-icon"></i>
              </a>
              <a href="fb-messenger://share?link={{CURRENT_URL}}" target="_blank">
                <i class="fab fa-facebook-messenger social-icon"></i>
              </a>
              <a href="https://twitter.com/intent/tweet?url={{CURRENT_URL}}" target="_blank">
                <i class="fab fa-twitter social-icon"></i>
              </a>
              <a href="mailto:?subject={{CURRENT_URL}}" target="_blank">
                <i class="fa fa-envelope social-icon"></i>
              </a>
              <a href="viber://forward?text={{CURRENT_URL}}" target="_blank">
                <i class="fab fa-viber social-icon"></i>
              </a>
              <a href="whatsapp://send?text=Check%20out%20this%20awesome%20content%20{{CURRENT_URL}}" target="_blank">
                <i class="fab fa-whatsapp social-icon"></i>
              </a>
            </div>

          </div>
        </div>

        <div class="heading">
          <h1>{{ article[articlesProperty.title] }}</h1>
        </div>
        <div class="subheading">
          <h2>{{ article[articlesProperty.subtitle] }}</h2>
        </div>
      } @else {
        <div class="loader-container article">
          <span class="loader"></span>
        </div>
      }


      <div class="advertisement">
        <div #advertisementSidebarSmall class="advertisement-sidebar">
        </div>
        @if(windowWidth > 768){
          <div id='div-gpt-ad-1723029415874-0' style='min-width: 160px; min-height: 50px;'>
          </div>
        } @else {
        }
      </div>

      @if (article !== undefined) {
        <div class="article-text">
          @for (textPart of article.text; track textPart; let i = $index) {
            @if (textPart.regular) {
              <div class="text">
                <p [innerHTML]="replaceNewlines(textPart.regular)"></p>
              </div>
            } @else if (textPart.bold) {
              <div class="text" style="font-weight: bold">
                <p [innerHTML]="replaceNewlines(textPart.bold)"></p>
              </div>
            } @else if (textPart.italic) {
              <div class="text" style="font-style: italic">
                <p [innerHTML]="replaceNewlines(textPart.italic)"></p>
              </div>
            } @else if (textPart.link) {
              <div class="link">
                <a class="link-style" href="{{textPart.link}}" target="_blank">
                  <i class="fa-solid fa-link"></i>
                  <p>{{ textPart.linkText }}</p>
                </a>
              </div>
            } @else if (textPart?.youtube?.changingThisBreaksApplicationSecurity) {
              <div
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(textPart.youtube.changingThisBreaksApplicationSecurity)"
                class="article-youtube">
              </div>
            } @else if (textPart.youtube) {
              <div class="article-youtube">
                <iframe [src]="embedSafe[i]" frameborder="0" webkitallowfullscreen mozallowfullscreen
                        allowfullscreen></iframe>
              </div>
            } @else if (textPart.image) {
              <div class="article-image">
                <img ngSrc="{{textPart.image}}" alt="{{article[ArticlesProperty.title]}}" fill=""
                     style="position: relative">
              </div>
            } @else if (textPart.instagram) {
              <div class="article-instagram">
                <iframe loading="lazy" width="320" height="580" frameborder="0"
                        data-instgrm-payload-id="instagram-media-payload-0"
                        scrolling="no" allowtransparency="true" allowfullscreen="true"
                        style="padding: 2px; margin: 10px auto !important; display:flex; box-shadow: 0 0 9px 0;
                border: 1px solid #d8d8d8; border-radius: 10px;"
                        [src]="embedSafe[i]">
                </iframe>
              </div>
            } @else if (textPart.facebook) {
              <!--<div [innerHTML]="sanitizeHtml(textPart.facebook)" class="article-facebook"></div>-->
              <div [innerHTML]="embedSafe[i]" class="article-facebook">
              </div>
              <!--<div [innerHTML]="html" class="article-facebook ">
              </div>-->
            } @else if (textPart.tiktok) {
              <div class="article-tiktok">
                <iframe loading="lazy" scrolling="no"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                        class="tiktok-iframe ng-star-inserted"
                        style="height: 739px; width: 324px; margin: 10px auto; border-radius: 9px; border: solid 1px #d7d7d7; display: block; visibility: unset; max-height: 739px;"
                        [src]="embedSafe[i]">
                </iframe>
              </div>
            } @else if (textPart.x) {
              <div [innerHTML]="embedSafe[i]" class="article-x">
              </div>
            }
            @if(shouldPlaceAdvertisement(i) && this.windowWidth < 768){
              <div class="lwdgt" data-wid="139"></div>
            }

            <!--@if (textPart.survey) {
              <div class="article-survey">
                <app-survey [survey]="textPart.survey"></app-survey>
              </div>
            }-->
          }
        </div>
      }
      @if(windowWidth < 768){
        <div id="hayat_ba_300x300_in_article_1_mobile" class="display-flex in-article-height">
        </div>
        <!-- new_hayat_ba_responsive_GA -->
<!--        <ng-adsense-->
<!--          [adClient]="'ca-pub-7451157250131553'"-->
<!--          [adFormat]="'auto'"-->
<!--          [fullWidthResponsive]="true"-->
<!--        ></ng-adsense>-->

<!--        <ins class="adsbygoogle"-->
<!--             style="display:block"-->
<!--             data-ad-client="ca-pub-7451157250131553"-->
<!--             data-ad-slot="7460079540"-->
<!--             data-ad-format="auto"-->
<!--             data-full-width-responsive="true">-->
<!--        </ins>-->
      }
      <div class="lwdgt" data-wid="6"></div>
      @if(windowWidth < 768){
        <div id="midasWidget__10687"></div>
      }
      <div id="ng_UzLidnmgx2" class="ng_ws"></div>

    </div>

    <div class="right">
      <div class="article-information">
        <div class="left-info" itemprop="datePublished">
          @if (article !== undefined) {
            <p style="margin-bottom: 10px; width: max-content;">{{ article[articlesProperty.display_name] }}</p>
            <div class="date">
              <p>{{ date }}</p>
              <p>{{ time }}</p>
            </div>
          } @else {
            <p style="margin-bottom: 10px; width: max-content;">...</p>
            <div class="date">
              <p>dd/mm/yy</p>
              <p>12:45</p>
            </div>
          }
        </div>
        <div class="right-info">
          <p>Podijelite ovu vijest</p>
          <div class="share-buttons">
            <a href="https://www.facebook.com/sharer.php?u={{CURRENT_URL}}" target="_blank">
              <i class="fab fa-facebook-f social-icon"></i>
            </a>
            <a href="fb-messenger://share?link={{CURRENT_URL}}" target="_blank">
              <i class='fab fa-facebook-messenger social-icon'></i>
            </a>
            <a href="https://twitter.com/intent/tweet?url={{CURRENT_URL}}" target="_blank">
              <i class='fab fa-twitter social-icon'></i>
            </a>
            <a href="mailto:?subject={{CURRENT_URL}}" target="_blank">
              <i class="fa fa-envelope social-icon"></i>
            </a>
            <a href="viber://forward?text={{CURRENT_URL}}" target="_blank">
              <i class='fab fa-viber social-icon'></i>
            </a>
            <a href="whatsapp://send?text=Check%20out%20this%20awesome%20content%20{{CURRENT_URL}}" target="_blank">
              <i class='fab fa-whatsapp social-icon'></i>
            </a>
          </div>
        </div>
      </div>
      <div class="other">
        <div #advertisementSidebar class="advertisement-sidebar">
        </div>

        <h2>{{ article?.category?.name }}</h2>
        @if (this.categoryArticles) {
          <div class="category-articles">
            <div *ngFor="let articles of this.categoryArticles" class="category-article">
              <a *ngIf="articles[articlesProperty.id] != article?.[articlesProperty.id]" class="photo-newest"
                 [routerLink]="'../../' + articles[articlesProperty.url_title] + '/' + articles[articlesProperty.id]"
                 (click)="removeArticle()">
                @if (!isVideo(articles.image_list[0]?.url)) {
                  <img ngSrc="{{articles.image_list[0]?.url || 'https://proba.hayat.ba/assets/logo-color.webp'}}"
                       fill="" alt="" style="position: relative">

                } @else {
                  <video
                    #videoElement
                    [src]="articles.image_list[0].url"
                    muted
                    autoplay
                    loop
                    playsinline
                  >
                  </video>
                }
                <h3>{{ articles[articlesProperty.title] }}</h3>
              </a>
            </div>
          </div>
        }

        <!--@if(windowWidth > 768){
          <div id='hayat_ba_300x600_sidebar_sticky_desktop'>
            <script type="text/javascript">
              (function() {
                googletag.cmd.push(function() {
                  if (window.innerWidth >= 1280)  {
                    googletag.defineSlot('/272140683/hayat.ba_300x600_sidebar_sticky_desktop', [[300,600],[160,600],[300,250],[300,300]], 'hayat_ba_300x600_sidebar_sticky_desktop').addService(googletag.pubads());
                    googletag.display('hayat_ba_300x600_sidebar_sticky_desktop');
                    stpd.initializeAdUnit('hayat_ba_300x600_sidebar_sticky_desktop');
                  }
                });
              })();
            </script>
          </div>
        }-->

      </div>
    </div>
  </div>
